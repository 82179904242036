import React from "react";
import Radio from "./Radio";
import "./App.css";

export default function App() {
  return (
    <div className="App">
      <h1>chill • step</h1>
      <div className="italic">noun</div>
      <ol>
        <li className="def">
          musical genre composed of dubstep tracks with chill undertones.
        </li>
        <li className="def">
          A kind of modern dubstep that's much quieter, less wild, and more
          controlled. The focus of most Chillstep (as the name implies) is to
          help you chill out, to relax.
        </li>
      </ol>
      <div className="italic usage">"Dude this Chillstep is the shit."</div>
      <div className="italic usage">
        "Bro, I'm not trying to rave, just throw on some chillstep"
      </div>
      <div className="italic">
        "Dude, check out this new dubstep track, it's so chill."
      </div>
      <div className="italic">"Yeah dog, that is some heady chillstep."</div>
      <div className="italic">"Late night burn sesh?"</div>
      <div className="italic usage-last">"Yea blunts and chillstep."</div>
      <Radio />
      <footer>
        <div>
          get featured:
          <span className="footerSection"> communitychillstep@gmail.com</span>
        </div>
      </footer>
    </div>
  );
}
