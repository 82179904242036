import React from "react";

const Radio = () => {
  return (
    <div className="radioContainer">
      <svg
        id="radio"
        xmlns="http://www.w3.org/2000/svg"
        width={696}
        height="751.2"
        viewBox="0 0 800 900"
      >
        <title>radio</title>
        <ellipse
          id="shadow"
          cx={345}
          cy={780}
          rx="342.3"
          ry="29.4"
          fill="#62352b"
          opacity="0.3"
        />
        <g data-name="radio 04" id="allRad" style={{ isolation: "isolate" }}>
          <path
            fill="#466455"
            d="M170.9 134.2L157.3 189.6 143.8 244.6 130.3 299.6 125.3 320.1 107.9 316.2 112 299.6 127 238.4 138.8 190.1 153.5 130.3 170.9 134.2"
          />
          <path
            fill="#466455"
            d="M190.5 45.5L190.4 46 190.4 46 170.3 127.9 157.1 124.7 177.2 42.6 177.3 42.2 190.5 45.5"
          />
          <path
            d="M198.1,46.3a1.2,1.2,0,0,1-1.5,1.3L190.4,46h0l-13.1-3.4-6.9-1.8a1.2,1.2,0,0,1-.7-1.8l17.8-28.8L193.3.6a1.2,1.2,0,0,1,2.2.6l.5,9.1Z"
            fill="#ec202c"
          />
          <path
            d="M122.5,209.6h-1.8l-7.7.6c-10.3.7-21.9,1.4-33,5.5-12.8,4.7-23,13.1-31.1,25.7-20.6,31.9-28.7,91.7-24.6,182.9l.2,5v1.1H35.1v-1.2c-.1-2.9-.2-5.7-.4-8.4,0-.6,0-1.3-.1-1.9-3-84.6,4.6-140,23-169.3,16.5-26.1,39.5-27.5,59.7-28.8l3.3-.2h2.5Z"
            fill="#5f3521"
          />
          <path
            d="M650.3,241.6c-8.1-12.6-18.3-21-31.1-25.7-11.1-4.1-22.7-4.9-33-5.5l-7.7-.6h-1.8l-.7,11h2.5l3.3.2c20.3,1.3,43.2,2.7,59.7,28.8,18.7,29.5,26.2,85.7,22.9,171.7l-.3,7.9v1.2h10.5v-3.1h0C679.2,334.6,671.2,273.8,650.3,241.6Z"
            fill="#5f3521"
          />
          <path
            d="M578.5,209.4v15.5c0,10.9-10.5,19.8-23.4,19.8H143.8a25.3,25.3,0,0,1-16.8-6.3,18,18,0,0,1-6.3-13.5V209.4c0-9.3,7.8-17.2,18.1-19.2a27.6,27.6,0,0,1,5.2-.5H555.2C568.1,189.6,578.5,198.5,578.5,209.4Z"
            fill="#b82025"
          />
          <ellipse cx="659.4" cy="442.3" rx="21.4" ry="21.5" fill="#9eb68e" />
          <ellipse cx="668.3" cy="506.9" rx={25} ry="25.1" fill="#62352b" />
          <path
            d="M57.7,442.3a21.4,21.4,0,0,1-28.3,20.4,21.6,21.6,0,0,1-5.1-38.2,20.7,20.7,0,0,1,10.1-3.6h1.9a21.4,21.4,0,0,1,20,13.9A21.1,21.1,0,0,1,57.7,442.3Z"
            fill="#9eb68e"
          />
          <path
            d="M52.3,507a25.1,25.1,0,0,1-25,25.1,23.5,23.5,0,0,1-6.2-.8,25.1,25.1,0,0,1,5.9-49.4h.3a25.1,25.1,0,0,1,25,25.1Z"
            fill="#62352b"
          />
          <path
            d="M656,751.2H40c-25,0-42.7-20.9-39.6-46.3L21.2,531.2l5.9-49.4,2.3-19.1,3.9-32.2,1.1-9.6.2-1.9,10.2-85.1c2.3-19,21.2-34.2,42.4-34.2H608.8c21.2,0,40.1,15.2,42.4,34.2l10.4,87,5,41.7,2.3,19.3,5.9,49.3,20.8,173.8C698.7,730.3,681,751.2,656,751.2Z"
            fill="#b0d3b4"
          />
          <path
            d="M668.5,695.7c2.8,23.5-13.6,42.9-36.7,42.9H61.7c-23.1,0-39.5-19.4-36.7-42.9L66.2,352.2c2.1-17.6,19.7-31.7,39.2-31.7H588.1c19.6,0,37.1,14.1,39.2,31.7Z"
            fill="#f3d1ad"
            opacity="0.64"
            style={{ mixBlendMode: "multiply" }}
          />
          <path
            d="M660.4,686.7c2.7,22.8-13.2,41.6-35.6,41.6H71.2c-22.4,0-38.4-18.8-35.6-41.6l40-333.4c2-17.1,19.1-30.7,38.1-30.7H582.4c19,0,36,13.7,38.1,30.7Z"
            fill="#fce4bf"
          />
          <g>
            <path
              fill="#e08f26"
              d="M109.7 410.6H206.10000000000002V444.99H109.7z"
            />
            <path
              fill="#fff0d9"
              d="M118.8 422.2H196.89999999999998V433.37H118.8z"
            />
            <path
              fill="#5f3521"
              d="M118.8 422.2H121.89999999999999V433.37H118.8z"
            />
            <path
              fill="#754d29"
              d="M122.9 422.2H125.60000000000001V433.37H122.9z"
            />
            <path fill="#8b5e3c" d="M127.1 422.2H129.4V433.37H127.1z" />
            <path fill="#8b5e3c" d="M131.2 422.2H133.1V433.37H131.2z" />
            <path fill="#a97c50" d="M135.4 422.2H137V433.37H135.4z" />
            <path fill="#a97c50" d="M178.9 422.2H180.5V433.37H178.9z" />
            <path fill="#8b5e3c" d="M182.6 422.2H184.5V433.37H182.6z" />
            <path
              fill="#8b5e3c"
              d="M186.4 422.2H188.70000000000002V433.37H186.4z"
            />
            <path
              fill="#754d29"
              d="M190.1 422.2H192.79999999999998V433.37H190.1z"
            />
            <path fill="#5f3521" d="M193.9 422.2H197V433.37H193.9z" />
          </g>
          <g>
            <path fill="#e08f26" d="M489.6 410.6H586V444.99H489.6z" />
            <path fill="#fff0d9" d="M498.8 422.2H576.9V433.37H498.8z" />
            <path fill="#5f3521" d="M573.8 422.2H576.9V433.37H573.8z" />
            <path fill="#754d29" d="M570 422.2H572.7V433.37H570z" />
            <path
              fill="#8b5e3c"
              d="M566.3 422.2H568.5999999999999V433.37H566.3z"
            />
            <path fill="#8b5e3c" d="M562.5 422.2H564.4V433.37H562.5z" />
            <path fill="#8b5e3c" d="M511.1 422.2H513V433.37H511.1z" />
            <path fill="#8b5e3c" d="M507 422.2H509.3V433.37H507z" />
            <path fill="#754d29" d="M502.8 422.2H505.5V433.37H502.8z" />
            <path fill="#5f3521" d="M498.7 422.2H501.8V433.37H498.7z" />
            <path
              fill="#ec202c"
              d="M553.6 422.1H557.2V433.32000000000005H553.6z"
            />
          </g>
          <g>
            <path
              d="M556.5,547.7a5.5,5.5,0,0,0-5.3-4.9H144.9a5.5,5.5,0,0,0-5.3,4.9L125.4,707a.8.8,0,0,0,.8,1H569.8a.8.8,0,0,0,.8-1Z"
              fill="#f26d57"
            />
            <path
              d="M396.3,651.4h69.8l.8,11.7H396.8ZM148.3,559.9,147,571.5h75.2l.8-11.7Zm244.9,11.7-.5-11.7h66.8l.8,11.7Zm-157.9,0,.8-11.7H303l-.5,11.7Zm238.2,0-.8-11.7h75.2l1.3,11.7Zm-157.9,0,.5-11.7h63.7l.5,11.7Zm-.4,11.2h65.5l.5,11.7H314.6Zm160,11.7-.8-11.7h75.6l1.3,11.7ZM234.5,582.8h67.6l-.5,11.7H233.7Zm-13,0-.8,11.7h-76l1.3-11.7Zm172.2,0h67.6l.8,11.7h-68Zm-79.5,22.9h67.3l.5,11.7H313.7Zm-81.3,0h68.3l-.5,11.7H232Zm161.7,0h68.3l.8,11.7H395Zm82.2,11.7-.8-11.7h75.5l1.3,11.7ZM219.8,605.6l-.8,11.7H143l1.3-11.7Zm-1.6,22.9-.8,11.7H140.1l1.3-11.7Zm260.2,11.7-.8-11.7h76.7l1.3,11.7ZM313.3,628.5h69.1l.5,11.7h-70Zm82.2,0h69l.8,11.7H395.9Zm-164.2,0h69l-.5,11.7H230.4ZM480,663.1l-.8-11.7h77.1l1.3,11.7ZM216.5,651.4l-.8,11.7H138.1l1.3-11.7Zm13,0h69.8l-.5,11.7H228.7Zm82.8,0h70.9l.5,11.7H311.9ZM481.7,686l-.8-11.7h77.2l1.3,11.7Zm-97.4-11.7.5,11.7H311l.5-11.7Zm-85.8,0L298,686H227.1l.8-11.7Zm169.3,0,.8,11.7H397.7l-.5-11.7Zm-252.9,0L214,686H136.3l1.3-11.7Z"
              fill="#653c36"
            />
          </g>
          <g>
            <ellipse cx={348} cy="431.6" rx="63.4" ry="63.7" fill="#c9bfa0" />
            <path
              d="M423.1,446.4h-.5l-12.4-3.1-6.9-1.8-11.9-3-2.6-.6a40.1,40.1,0,0,0,.5-6.2,41.7,41.7,0,0,0-13.7-31l-.5-.5a41.3,41.3,0,0,0-27.2-10.2H347a41.6,41.6,0,0,0-40.7,41.7,39.1,39.1,0,0,0,.5,6.2l-2.2.5-12,2.9-7,1.7-12.3,3h-.4l-4.2,1c.2,1.6.5,3.2.8,4.8a80.4,80.4,0,0,0,21.8,40.4A79.3,79.3,0,0,0,347,515.9h1a79.4,79.4,0,0,0,56.7-23.6,80.3,80.3,0,0,0,21.8-40.6q.4-2.1.7-4.3Z"
              fill="#fff0d9"
            />
            <path
              d="M348,327.7h-1c-56.5.5-102.3,46.9-102.3,103.8S290.5,534.9,347,535.4h1c57,0,103.3-46.6,103.3-103.9S405,327.7,348,327.7Zm-1,27.4h1a75.5,75.5,0,0,1,47.5,16.8l.6.5.2.2a76.6,76.6,0,0,1,26.4,73.7c-6.8,35.2-37.7,61.8-74.7,61.8h-1a76.2,76.2,0,0,1-73.7-62.1,72.9,72.9,0,0,1-1.4-14.3C271.9,389.7,305.5,355.6,347,355.1Z"
              fill="#62352b"
            />
            <ellipse cx={348} cy="431.6" rx="26.8" ry="26.9" fill="#eebf86" />
            <path
              d="M348,395.4h-1a36.1,36.1,0,0,0,0,72.2h1a36.2,36.2,0,0,0,0-72.3Zm0,53.8h-1a17.6,17.6,0,0,1,0-35.2h1a17.7,17.7,0,0,1,0,35.4Z"
              fill="#e08f26"
            />
            <path
              fill="#5f3521"
              d="M375.6 400.5L372.1 397.3 395.5 371.8 399 375 375.6 400.5"
            />
          </g>
        </g>
      </svg>
      <iframe
        title="soundcloud"
        height="166px"
        scrolling="no"
        frameborder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/410596311&color=%232c425b&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
      />
    </div>
  );
};

export default Radio;
